import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SvgSlider from '../components/svg-slider/svg-slider';

const query = graphql`
  {
    allFile(
      filter: { relativePath: { glob: "howto/slider_signal/*" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 799) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`;

const HowToSignalSlider = () => {
  return <SvgSlider images={useStaticQuery(query)} />;
};

export default HowToSignalSlider;
