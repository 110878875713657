import React from 'react';
import { useTranslation } from 'react-i18next';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import HowToContent from '../content/de/howto-content';

const HowToPage = () => {
  const { t } = useTranslation('howto');
  return (
    <PageWrapper padding>
      <SEO title={t('header.title')} />
      <HeroState title={t('header.title')} imageName="machine--front-black" />
      <HowToContent />
    </PageWrapper>
  );
};

export default HowToPage;
