import React from 'react';
import Image from 'gatsby-image';
import TinySlider from 'tiny-slider-react';

import './svg-slider.module.scss';

const settings = {
  controls: true,
  controlsText: ['', ''],
  nav: false,
};

const SvgSlider = ({ images }) => (
  <TinySlider settings={settings}>
    {images.allFile.edges.map((edge, i) => (
      <div key={i}>
        {!edge.node.childImageSharp && edge.node.extension === 'svg' ? (
          <img src={edge.node.publicURL} alt={''} />
        ) : (
          <Image {...edge.node.childImageSharp} />
        )}
      </div>
    ))}
  </TinySlider>
);

export default SvgSlider;
