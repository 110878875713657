import React from 'react';
import { Trans } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import HowToList from '../../components/how-to-list/how-to-list';
import ContentColumn from '../../components/content-column/content-column';
import CoffeeSlider from '../how-to-coffee-slider';
import SteamSlider from '../how-to-steam-slider';
import CleanSlider from '../how-to-clean-slider';
import SignalSlider from '../how-to-signal-slider';

const HowToContent = () => {
  return (
    <Container>
      <Row>
        <ContentColumn md={{ offset: 1, span: 10 }} spacingY>
          <Trans i18nKey="text-01" ns="howto">
            <h2>Traditionelle Handhabung. Nach modernsten Anforderungen.</h2>
            <p>
              Eine Leva, also eine Handhebelmaschine, erzeugt den Druck, der für
              einen Espresso benötigt wird, durch das Spannen einer Feder, indem
              man einen Hebel zieht, statt, wie heute konventionell üblich, mit
              einer Pumpe. Die vollkommen händische Bedienung der Leva macht
              ihre Handhabung zur reinste Herzensangelegenheit.
            </p>
            <p>
              Mit Ihrer MANUMENT Leva-Maschine beleben Sie die Sinnlichkeit des
              klassischen Leva-Verfahrens wieder. Auf dieser Seite erklären wir
              Ihnen, wie Sie dabei genau vorgehen.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row>
        <ContentColumn md={{ offset: 1, span: 10 }} spacingY className={'pt-0'}>
          <div
            style={{
              width: '100%',
              paddingBottom: '56.25%',
              position: 'relative',
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
              title="vimeo-player"
              src="https://player.vimeo.com/video/540531763"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} className="p-0">
          <CoffeeSlider />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-02" ns="howto">
            <h2>Wie Sie Kaffee zubereiten.</h2>
          </Trans>
          <HowToList>
            <Trans i18nKey="text-03" ns="howto">
              <li>Füllen Sie den Wassertank</li>
              <li>Schalten Sie die Maschine auf ON</li>
              <li>
                Stellen Sie Ihre Wunsch-Temperatur am Temperatur-Regler ein
              </li>
              <li>
                Befüllen Sie den Siebträger und tampen Sie den gemahlenen Kaffee
              </li>
              <li>Spannen Sie den Siebträger fest in die Maschine ein</li>
              <li>Ziehen Sie den Leva-Hebel</li>
              <li>
                Öffnen Sie zur Extraktion das Flow-Ventil mit dem Flow-Hebel
              </li>
              <li>
                Schließen Sie das Flow-Ventil, um die Extraktion zu beenden
              </li>
              <li>
                Öffnen und schließen Sie das Flow-Ventil um das Restwasser zu
                entlassen
              </li>
              <li>Entnehmen und reinigen Sie den Siebträger</li>
            </Trans>
          </HowToList>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} className="p-0">
          <SteamSlider />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-04" ns="howto">
            <h2>Wie Sie Dampf erzeugen.</h2>
          </Trans>
          <HowToList>
            <Trans i18nKey="text-05" ns="howto">
              <li>Schalten Sie auf MANU</li>
              <li>
                Stellen Sie Ihre Wunsch-Temperatur am Temperatur-Regler ein –
                min. 120°C
              </li>
              <li>
                Öffnen Sie den Dampfhebel für einen Moment leicht, um die
                Dampflanze zu spülen
              </li>
              <li>
                Öffnen Sie den Dampfhebel bis zum Anschlag und schäumen Sie
                Milch
              </li>
              <li>
                Spülen Sie die Dampflanze und reinigen sie mit einem feuchten
                Tuch
              </li>
            </Trans>
          </HowToList>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} className="p-0">
          <CleanSlider />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-06" ns="howto">
            <h2>Wie Sie die MANUMENT Leva-Maschine reinigen.</h2>
          </Trans>
          <HowToList>
            <Trans i18nKey="text-07" ns="howto">
              <li>Lösen und spülen Sie Siebträger und Filter</li>
              <li>
                Verwenden Sie das beiliegende Werkzeug um Schraube Duschsieb und
                Dusche zu lösen und entnehmen
              </li>
              <li>Spülen Sie Schraube Duschsieb und Dusche</li>
              <li>
                Reinigen Sie den Kupplungsbereich innen mit einem feuchten Tuch
              </li>
              <li>
                Wischen Sie die Dusch-Teile trocken ab und montieren sie zurück
                an die Maschine
              </li>
              <li>Entnehmen und spülen Sie die Tropfschale</li>
            </Trans>
          </HowToList>
        </ContentColumn>
      </Row>
      <Row className={'mb-lg-10 mb-5'}>
        <Col md={6} className="p-0">
          <SignalSlider />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-08" ns="howto">
            <h2>Was Sie von den Signalleuchten ablesen.</h2>
            <p>
              <strong>Grün</strong>
              <br />
              Hebel in Ausgangsposition – die Leva ist startbereit
            </p>
            <p>
              <strong>Grün blinkt</strong>
              <br />
              Hebel wurde gezogen, Heizung ist eingeschaltet – das Wasser
              erreicht die eingestellte Temperatur
            </p>
            <p>
              <strong>Blau blinkt</strong>
              <br />
              Druck wurde erreicht – die Leva ist bereit zur Extraktion
            </p>
            <p>
              <strong>Rot</strong>
              <br />
              Fehleranzeige – Restwasser nicht abgelassen, Heizung schaltet
              automatisch aus. Bitte spülen! Bei stetem rotem Leuchten schlagen
              Sie bitte im Benutzerhandbuch nach.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
    </Container>
  );
};

export default HowToContent;
